import Sentry from "@clcdev/gatsby-plugin-sentry";
import {
    isRequestErrorResult,
    isResponseErrorResult,
    isErrorResult,
} from "@clcdev/leadspedia";
import CampaignHandler from "../utils/campaign-handler";

export const submitApp = async (approvePartialLead) => {
    const campaignId = CampaignHandler.campaignId;
    const postCampaignID = CampaignHandler.currentCampaign?.postCampaignID;
    const postCampaignKey = CampaignHandler.currentCampaign?.postCampaignKey;

    const response = await approvePartialLead();
    const message = "Error occurred during app submission";

    if (isRequestErrorResult(response)) {
        Sentry.captureEvent({
            message: `${message}: error creating request`,
            level: "error",
            exception: response.data.originalError,
            extra: {
                message: response.data.message,
                campaignId,
                postCampaignID,
                postCampaignKey,
            },
        });
    } else if (isResponseErrorResult(response)) {
        Sentry.captureEvent({
            message: `${message}: received response error`,
            level: "error",
            exception: response.data.originalError,
            extra: {
                message: response.data.message,
                campaignId,
                postCampaignID,
                postCampaignKey,
            },
        });
    } else if (isErrorResult(response)) {
        Sentry.captureEvent({
            message: `${message}: request resulted in an error`,
            level: "error",
            exception: response.data.originalError,
            extra: {
                message: response.data.message,
                campaignId,
                postCampaignID,
                postCampaignKey,
            },
        });
    } else {
        CampaignHandler.setLeadResponse(response.data);
        return response;
    }
};