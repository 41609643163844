import React, { useState } from "react";
import { navigate } from "gatsby";
import { useMixpanel } from "gatsby-plugin-mixpanel";
import { config } from "config";
import { useApplicationContext } from "../state";
import SectionContainer from "../components/SectionContainer";
import { submitApp } from "../api/leads";
import { normalize } from "../utils/normalize";
import CampaignHandler from "../utils/campaign-handler";
import { readObject } from "../utils/objects";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import ConfigRenderer from "../composite/ConfigRenderer";

const SubmitAppTemplate = (props) => {
    const [submitted, setSubmitted] = useState(false);
    const context = useApplicationContext();
    const { customLayout } = useSiteMetadata();
    const mixpanel = useMixpanel();

    const {
        getLeadField,
        getLeadMeta,
        approvePartialLead,
        stepsData,
        addStepToStepsData,
        clearContext,
        addToastError,
    } = context;

    const stepName = props.pageContext.step;

    const [{
        inputs,
        nextStep,
    }] = useState(() => ({
        ...readObject(config.steps[stepName]),
    }));

    const onSubmitClick = async () => {
        if (submitted) return;
        let retries = 0;

        const doSubmit = async () => {
            const resolvedNextStep = nextStep();
            // submitApp() could fail, but as of now, it is not actionable.
            // If we do receive actionable errors, they could rethrow from inside
            // submitApp(), and be caught here, informing the user and being handled
            // somehow
            const response = await submitApp(approvePartialLead);

            if (!response) {
                if (++retries < 3) {
                    console.log(`Retrying... (attempt ${retries})`);
                    return doSubmit();
                }
                const toastMessage = customLayout
                    ? "Please refresh the page"
                    : "Please try again";

                addToastError(`Oops! Something went wrong. ${toastMessage}`);
                clearContext();
                navigate("/");
                return false;
            } else {
                const leadAccepted = response
                    && response.data
                    && response.data.message.toLowerCase() === "lead accepted";

                const isNameTest = getLeadField("firstName").toLowerCase() === "test" || getLeadField("lastName").toLowerCase() === "test";
                const isTestLead = getLeadMeta().testLead || isNameTest;
                // fire postSubmit event
                if (process.env.GATSBY_ACTIVE_ENV === "production") {
                    if (!isTestLead) {
                        if (leadAccepted) {
                            mixpanel.track("Lead", {
                                appVersion: config.version,
                                versionNumber: config.rawVersion,
                            });
                            await CampaignHandler.postSubmit();
                        }
                    }
                } else {
                    if (leadAccepted) {
                        console.log("Mixpanel Track: TestLead");
                        mixpanel.track("TestLead");
                    }
                }

                setSubmitted(true);

                if (!stepsData.includes(resolvedNextStep)) {
                    addStepToStepsData(resolvedNextStep);
                }
                navigate(normalize(resolvedNextStep));
                return true;
            }
        };
        return await doSubmit();
    };

    return (
        <SectionContainer>
            <ConfigRenderer
                stepName={stepName}
                context={context}
                step={config.steps[stepName]}
                inputs={inputs}
                nextStep={nextStep}
                onSubmit={onSubmitClick}
            />
        </SectionContainer>
    );
};

export default SubmitAppTemplate;